:root {
  --dark: #292929;
  --primary: #002a7c;
  --primary-lighter: #0038b7;
  --gray: #cacaca;
  --gray-lighter: #eaeaea;
  --gray-dark: #8c8c8c;
  --secondary: #d4d5d0;
  --secondary-lighter: rgba(212, 213, 208, 0.5);
  --white: #fff;
  --broken-white: #fafafa;
  --green: green;
  --pink: rgb(255, 235, 236);
  --red-lighter: rgba(222, 0, 0, 0.7);
  --red: #de0000;
  --red-darker: #b20000;
  --background: #f4f4f4;
  --alert-bg: rgb(247, 209, 213);
}

html {
  height: 100%;
}

html.no-scroll {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background: var(--background);
  font-weight: 200;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  font-family: "RockwellStd", sans-serif;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.relative {
  position: relative;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.align-self-center {
  align-self: center;
}

.align-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.m-4 {
  margin: 1rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 2rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-auto {
  margin-top: auto;
}

.p-1 {
  padding: 1rem;
}

.px-1 {
  padding: 0 1rem;
}
.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.w-fit {
  width: fit-content;
}

.center-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.page-width {
  width: 1200px;
  max-width: 100%;
  padding: 20px;
}

.button {
  border-radius: 20px;
  border: 0;
  padding: 8px 16px;
  background-color: var(--primary);
  color: var(--white);
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
}

.button.disabled,
.button:disabled {
  background-color: var(--primary-lighter);
  cursor: not-allowed;
}

.button:hover {
  background-color: var(--primary-lighter);
  transition: all 0.1s linear;
}

.button-danger {
  background-color: var(--red);
}

.button-danger:hover {
  background-color: var(--red-darker);
}

.button-danger:disabled {
  cursor: not-allowed;
  background-color: var(--red-lighter);
}

.button-secondary {
  border-radius: 20px;
  border: 0;
  padding: 8px 16px;
  background-color: var(--background);
  color: var(--primary);
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;
}

.button-secondary:hover {
  background-color: var(--gray-lighter);
  transition: all 0.1s linear;
}

.button.outlined {
  background-color: var(--white);
  color: var(--primary);
  border: 1px solid var(--primary);
}

.button.outlined:hover {
  background-color: var(--background);
}

.white-padded-box {
  background-color: var(--white);
  border-radius: 8px;
  padding: 32px;
}

.hidden {
  display: none !important;
}

.text-overflow-elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

a,
a:visited {
  color: var(--primary);
}

a:hover {
  color: var(--primary-lighter);
}

.cursor-pointer {
  cursor: pointer;
}

.cell-operation {
  cursor: pointer;
}
