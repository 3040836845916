.upload-group {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  padding: 24px 0 8px;
  margin-top: 3px;
}

.upload-group .thumbnail {
  max-width: 200px;
  margin-right: 25px;
}

.upload-group .filename {
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 8px;
}

.upload-group .upload-button {
  margin-top: 4px;
  white-space: pre;
}

.upload-group .upload-button:hover {
  transition: all 0.2s linear;
}

.upload-group input {
  display: none;
}

.upload-group .label {
  position: absolute;
  left: 0;
  display: block;
  transition: 0.2s;
  color: var(--gray-dark);
  font-size: 18px;
  cursor: text;
  top: 0;
}

.upload-group .delete-highlight {
  cursor: pointer;
  margin-left: 25px;
}

.upload-group .button-wrapper {
  display: flex;
  align-items: center;
}
