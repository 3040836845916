input[type],
select {
  font-size: 15px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input:focus-visible, input:focus {
  outline: none;
}

.input-with-img {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  width: 100%;
  padding: 15px 0 0;
  margin-top: 3px;
}

.form-field {
  border: none;
  border-bottom: 1px solid var(--gray);
  padding: 4px 2px;
  width: 100%;
  margin-top: 10px;
}

.form-field.image {
  padding-left: 40px;
}

.form-field.readonly {
  background-color: var(--background);
}

.form-field:focus,
.form-field:focus-visible {
  outline: none;
  border-bottom: 3px solid var(--gray);
  margin-bottom: -2px;
}

.form-field::placeholder {
  color: transparent;
}

.form-field ~ .label {
  position: absolute;
  left: 0;
  display: block;
  transition: 0.2s;
  color: var(--gray-dark);
  font-size: 18px;
  cursor: text;
  top: 0;
}

.form-field:placeholder-shown ~ .label {
  font-size: 18px;
  cursor: text;
  top: 24px;
}

.form-field:focus ~ .label {
  position: absolute;
  display: block;
  transition: 0.2s;
  font-size: 18px;
  font-weight: 700;
  top: 0;
}

.form-field:placeholder-shown ~ .label.with-img {
  left: 37px;
}

.form-field:focus ~ .label.with-img {
  left: 0;
}

.input-with-img .required-asterisk {
  margin-left: -10px;
  margin-top: 20px;
  color: var(--gray);
  font-weight: 400;
  font-size: 16px;
  line-height: 125.8%;
  text-align: right;
  letter-spacing: 0.025em;
}

.input-img {
  width: 25px;
  margin-right: 7px;
  position: absolute;
  left: 2px;
  top: 26px;
}

.input-eye {
  width: 25px;
  margin-right: 7px;
  position: absolute;
  right: 0;
  color: var(--primary);
}
