.item-form-wrapper {
  margin: 10px 20px;
}

.item-form-wrapper .item-form {
  width: 800px;
  max-width: 100%;
}

.item-form-wrapper .item-form .description {
  height: 300px;
}

.item-form-wrapper .input-with-img {
  width: 300px;
  max-width: 100%;
}

.item-form-wrapper .tours-select-wrapper .selected-tours {
  align-items: center;
  width: 300px;
  max-width: 100%;
  padding: 0 10px;
}

.item-form-wrapper .tours-select-wrapper .selected-tours.empty {
  padding: 0;
}

.item-form-wrapper .tours-select-wrapper .selected-tours .selected-tour {
  display: flex;
  align-items: center;
  margin: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: var(--gray-light);
  font-weight: 500;
  width: 270px;
  max-width: 100%;
}

.item-form-wrapper
  .tours-select-wrapper
  .selected-tours
  .selected-tour
  .delete-tour-button {
  margin-left: auto;
  cursor: pointer;
  color: var(--primary);
}

.item-form-wrapper .tours-select-wrapper .tours-select {
  display: flex;
  align-items: center;
  width: 300px;
  max-width: 100%;
}

.item-form-wrapper .tours-select-wrapper .tours-select .add-tour-button {
  cursor: pointer;
  color: var(--primary);
}

.item-form-wrapper .images-and-video,
.item-form-wrapper .audio {
  width: 800px;
  max-width: 100%;
}

.item-form-wrapper .images-and-video .images-and-video-label,
.item-form-wrapper .audio .audio-label,
.item-form-wrapper .tours-select-wrapper .tours-select-label {
  color: var(--gray-dark);
  font-size: 18px;
}

.item-form-wrapper .mm-table {
  max-width: 800px;
  height: 300px;
}

.item-form-wrapper .mm-table .delete-file {
  color: var(--red);
}
