.tours {
  display: flex;
  justify-content: center;
  padding: 25px;
}

.tours .tours-wrapper {
  max-width: 1200px;
  width: 100%;
}

.tours .tours-table {
  height: 800px;
  max-width: 100%;
}

.tours .table-header {
  display: flex;
  justify-content: right;
  padding: 25px 0;
  gap: 25px;
}
