.back-button {
  color: var(--black);
  text-decoration: none;
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 18px;
}

.back-button:visited {
  color: var(--black);
}
