@font-face {
  font-family: 'RockwellStd';
  src: url('./Monotype - RockwellStd.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RockwellStd';
  src: url('./Monotype - RockwellStd-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'RockwellStd';
  src: url('./Monotype - RockwellStd-Italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'RockwellStd';
  src: url('./Monotype - RockwellStd-Light.otf') format('opentype');
  font-weight: 300; /* Example weight for light */
  font-style: normal;
}
