.items .items-table {
  height: 800px;
  max-width: 100%;
}

.items .table-header {
  display: flex;
  justify-content: right;
  padding: 25px 0;
  gap: 25px;
}

.delete-item-dialog-content .tour-listing {
  margin: 25px 0;
}

.delete-item-dialog-content .button-wrapper {
  display: flex;
  justify-content: right;
}
