.tour-wrapper .tour-form {
  width: 100%;
}

.tour-wrapper .tour-form .input-with-img {
  width: 300px;
}

.tour-wrapper .tour-form .buttons {
  gap: 25px;
  display: flex;
  justify-content: end;
}

.tour-wrapper .delete-dialog-content .description {
  font-size: 18px;
}

.tour-wrapper .delete-dialog-content .button-wrapper {
  display: flex;
  justify-content: end;
  margin-top: 25px;
}

.tour-wrapper .items-table {
  margin-top: 25px;
  height: 500px;
  display: flex;
  flex-direction: column;
}

.tour-wrapper .items-table button {
  margin-bottom: 25px;
  align-self: end;
}

.add-item-dialog-content .items-table {
  height: 400px;
}

.tour-wrapper .languages-wrapper {
  gap: 25px;
  margin-top: 25px;
  margin-bottom: 15px;
}

.tour-wrapper .languages-wrapper .languages-title {
  font-size: 18px;
  color: var(--gray-dark);
}
