.header-wrapper {
    padding: 30px;
}

.header-wrapper .header {
    align-items: center;
    gap: 25px;
    font-size: 25px;
    justify-content: space-between;
}

.header-wrapper .header .logo {
    fill: var(--primary);
}

.header-wrapper .header .logo {
    width: 60px;
}

.header-wrapper .header .content {
    display: flex;
    gap: 50px;
    align-items: center;
}
